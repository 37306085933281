<template>
  <div class="">
    <span
      v-if="item?.update"
      class="d-flex"
    >
       <d-text-field
         v-model.number="item.instalmentPercentage"
         size="sm"
         class="label-text"
       />
      <span class="d-flex align-items-center ml-2">%</span>
    </span>
    <span v-else>
      {{ item.instalmentPercentage }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: this
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .label-text {
  input {
    border: 1px solid #9b9b9b;
  }
}
</style>
